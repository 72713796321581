<template>
  <!-- 粪污利用记录 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('wasteUsing_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="120" ref="form" :model="form" :rules="rules">
        <!-- <FormItem label="利用设备" prop="deviceId">
          <Select style="width: 300px" v-model="form.deviceId">
            <Option
              :value="item.id"
              v-for="(item, index) in deviceList"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem> -->
        <FormItem label="集散点" prop="collectionId">
          <Select style="width: 300px" v-model="form.collectionId">
            <Option
              :value="item.id"
              v-for="(item) in socialList"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="粪污收纳量" prop="manureStorageAmount">
          <Input
            style="width: 300px"
            placeholder="请填写粪污收纳量"
            v-model="form.manureStorageAmount"
          ></Input>
        </FormItem>
        <FormItem label="秸秆收纳量" prop="strawStorageAmount">
          <Input
            style="width: 300px"
            placeholder="请填写秸秆收纳量"
            v-model="form.strawStorageAmount"
          ></Input>
        </FormItem>
        <FormItem label="日期" prop="date">
          <DatePicker transfer type="date" v-model="form.date"></DatePicker>
        </FormItem>
        <FormItem label="联系人" prop="concat">
          <Select style="width: 300px" v-model="form.concat">
            <Option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </Option>
          </Select>
          <!-- <Input style="width: 300px" v-model="form.concat"></Input> -->
        </FormItem>
        <FormItem label="车辆" prop="car">
          <Select style="width: 300px" v-model="form.car">
            <Option
              :value="item.plate"
              v-for="(item, index) in cars"
              :key="index"
              >{{ item.plate }}</Option
            >
          </Select>
        </FormItem>
        <!-- <FormItem label="开始时间" prop="startTime">
          <DatePicker
            type="date"
            placeholder="请选择开始时间"
            style="width: 300px"
            v-model="form.startTime"
          ></DatePicker>
        </FormItem> -->
        <!-- <FormItem label="结束时间" prop="endTime">
          <DatePicker
            type="date"
            placeholder="请选择结束时间"
            style="width: 300px"
            v-model="form.endTime"
          ></DatePicker>
        </FormItem> -->
        <FormItem label="地址">
          <Input
            style="width: 300px"
            placeholder="请填写地址"
            v-model="form.address"
          ></Input>
        </FormItem>
        <!-- <FormItem label="内容">
          <Input
            style="width: 300px"
            type="textarea"
            placeholder="请填写内容"
            v-model="form.content"
          ></Input>
        </FormItem>-->
        <FormItem label="备注">
          <Input
            style="width: 300px"
            type="textarea"
            placeholder="请填写备注"
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "集散点",
            width: 250,
            key: "collectionName",
          },
          {
            title: "畜禽粪污收纳量(kg)",
            width: 200,
            key: "manureStorageAmount",
          },
          {
            title: "秸秆收纳量(kg)",
            width: 150,
            key: "strawStorageAmount",
          },
          {
            title: "经办人",
            width: 150,
            key: "concat",
          },
          {
            title: "车辆",
            width: 150,
            key: "car",
          },
          {
            title: "日期",
            width: 200,
            render: (h, { row }) => {
              let str = row.date.substring(0, 10);
              return <span>{str}</span>;
            },
          },
          {
            title: "地址",
            key: "address",
          },
          {
            title: "备注",
            key: "remark",
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        // deviceId: "", //设备Id
        collectionId: "", //集散点Id
        address: "", //地点
        // amount: "", //用量
        manureStorageAmount: "", //粪污收纳量
        strawStorageAmount: "", //秸秆收纳量
        car: "",
        date: new Date(), //日期
        concat: "", //联系人
        // serialNo:'',
        // startTime: "",
        // endTime: "",
        // content: "", //内容
        remark: "",
      },
      rules: {
        // deviceId: [{ required: true, message: "请选择设备" }],
        collectionId: [{ required: true, message: "请选择集散点" }],
        // startTime: [{ required: true, message: "请选择开始时间" }],
        // endTime: [{ required: true, message: "请选择结束时间" }],
        // amount: [{ required: true, message: "请填写收纳量" }],
        manureStorageAmount: [{ required: true, message: "请填写粪污收纳量" }],
        strawStorageAmount: [{ required: true, message: "请填写秸秆收纳量" }],
        date: [{ required: true, type: "date", message: "请填写收纳时间" }],
        concat: [{ required: true, message: "请填写联系人" }],
      },
      search_data: {},
      //设备列表
      deviceList: [],
      //集散点
      socialList: [],
      // 车辆列表
      cars: [],
      users:[],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed:{
    config(){
      let  config = {
        filter: {
          add: {
            addBtnName: "新增粪污利用记录",
            ca: "wasteUsing_add",
          },
          width: 200,
          filterBox: [
            // {
            //   conditionName: "利用设备",
            //   component: "select",
            //   field: "deviceId",
            //   defaultValue: "",
            //   data: [],
            //   parameterField: "id",
            //   showField: "name",
            //   isCallback: true,
            //   mark: "1",
            // },
            {
              conditionName:"集散点",
              component:"select",
              field:"collectionId",
              defaultValue:'',
              data:this.socialList,
              parameterField:"id",
              showField:"name"
            },
            {
              conditionName:'车辆',
              component:"input",
              field:"car",
              defaultValue:""
            },
            {
              conditionName:'经办人',
              component:"input",
              field:"concat",
              defaultValue:""
            },
            {
              conditionName:'地址',
              component:"input",
              field:"address",
              defaultValue:""
            },
          ],
        },
        page: {
          ...this.page
        },
      };
      return config
    }
  },
  methods: {
    // 用户管理
    getUsers() {
      this.$post(this.$api.USER_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 999999,
      }).then((res) => {
        this.users = res.list;
      });
    },
    // 获取车辆信息
    getCars() {
      this.$post(this.$api.CAR_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 9999,
      }).then((res) => {
        this.cars = res.list;
      });
    },

    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增粪污利用记录信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("wasteUsing_check")) return;
      this.table.loading = true;
      this.$post(this.$api.WASTE_USING.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.page.total = res.total * 1;
          this.table.data = res.list;

        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //利用设备
    getDevice() {
      this.$post(this.$api.USE_DEVICE.LIST, {
        siteType: 3,
      }).then((res) => {
        this.deviceList = res.list;
        this.config.filter.filterBox[0].data = res.list;
      });
    },

    //集散点
    getSocialList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: "3",
        pageNo: 1,
        pageSize: 99999,
      }).then((res) => {
        this.socialList = res.list;
      });
    },

    submit() {
      // params.startTime = moment(this.form.startTime).format("YYYY-MM-DD");
      // params.endTime = moment(this.form.endTime).format("YYYY-MM-DD");
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let url;
        // if (params.id) {
        //   url = this.$api.WASTE_USING.UPDATE;
        // } else {
        url = this.$api.WASTE_USING.ADD;
        // }
        let params = { ...this.form };
        params.date = moment(params.date).format("YYYY-MM-DD HH:mm:ss");
        params.concat = this.users.find(
          (item) => item.id == params.concat
        ).name;
        // params.collectionId = this.socialList.find(
        //   (item) => item.id == params.collectionId
        // ).name;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        // deviceId: "",
        collectionId: "",
        // startTime: "",
        // endTime: "",
        // amount: "",
        manureStorageAmount: "",
        strawStorageAmount: "",
        concat: "",
        date: new Date(),
        address: "",
        // content: "",
        remark: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    // this.getDevice();
    this.getSocialList();
    this.getCars();
    this.getUsers();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>
